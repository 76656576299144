<template>
  <div class="text-center">
    <div
      v-if="type === 'html'"
      v-html="sanitizeContent(content) "
    />
    <v-responsive
      v-if="type === 'img'"
      class="iframe-container"
    >
      <iframe
        :src="content"
        sandbox
        class="iframe"
      />
    </v-responsive>
  </div>
</template>

<script>

export default {
    name: 'PreviewContent',
    props: {
        content: {
            type: String, 
            default: ''
        }, 
        type: {
            type: String, 
            default: ''
        }
    }, 
    methods: {
        sanitizeContent(content) {
          //Remove script tags
          content = content.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')
          // Remove any potentially dangerous attributes
          content = content.replace(/<[^>]*(?:javascript|vbscript):[^>]*>/gi, '')
          content = content.replace(/<[^>]*style=["'][^"']*expression[^"']*["'][^>]*>/gi, '')
          return content
        }
    }
}
</script>

<style>
.iframe-container {
  position: relative;
  height: 0;
  padding-bottom: 100%; /* 16:9 aspect ratio */
}

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>